import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DatatableComponent } from '@siemens/ngx-datatable';
import {
  DeleteConfirmationDialogResult,
  ElementDimensions,
  MenuItem,
  SiActionDialogService,
  SiModalService,
} from '@simpl/element-ng';
import * as _ from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  startWith,
  Subject,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { ActiveProjectState } from '../../../../shared/active-project/models/active-project-state.model';
import { ThingInstanceUpdateFallback } from '../../../../shared/api-client/services/device-api-client/models/device.constants';
import { Device } from '../../../../shared/api-client/services/lcas-api-client/models/device.model';
import { Location } from '../../../../shared/api-client/services/lcas-api-client/models/location.model';
import { ProductType } from '../../../../shared/api-client/services/lcas-api-client/models/product-type.enum';
import { UpdateDevice } from '../../../../shared/api-client/services/lcas-api-client/models/update-device.model';
import { DevicesTableRow } from '../../../../shared/devices-commissioning/devices-table/models/devices-table-row.model';
import { EdgeConnectivityModalService } from '../../../../shared/edge-connectivity-modal.service';
import { ThingInstanceUpdateModalComponent } from '../../../../shared/modal-dialogs/thing-instance-update-modal/thing-instance-update-modal.component';
import { WirelessDeviceType } from '../../../../shared/models/business/wireless-device-type.enum';
import {
  WIRELESS_ROOM_SENSORS,
  WirelessRoomSensorType,
} from '../../../../shared/models/business/wireless-room-sensor-type.enum';
import { EdgeConnectivityInfo } from '../../../../shared/models/edge-connectivity-info.model';
import { UpdateProjectStatusService } from '../../../../shared/services/update-project-status.service';
import { ThingVersionComparerService } from '../../../../shared/thing-version-comparer/thing-version-comparer.service';
import {
  CURSOR_STYLE_DEFAULT,
  CURSOR_STYLE_POINTER,
  MD_BREAKPOINT_SIZE_PIX_FOR_DEVICES_TABLE,
} from '../../constants/breakpoints.constants';
import { statusMapping } from '../../constants/device-status.constants';
import {
  MaximumLengthForName,
  MinimumLengthForName,
} from '../../constants/reactive-form-input-constants';
import { SetupFormService } from '../../devices-setup-form/setup-form/services/setup-form.service';
import {
  LoadedStatus,
  LocationsState,
} from '../../location-store/models/location.mode';
import { LocationActions } from '../../location-store/store/location.actions';
import {
  selectBuildingProductLoadedStatus,
  selectProducts,
} from '../../location-store/store/location.reducers';
import { DeviceActionService } from '../../services/device-action.service';
import { FormAbstractionService } from '../../services/form-abstraction.service';
import { DeviceDetailsRow } from './models/device-details-row.model';
import { DeviceRow } from './models/device-row.model';
import { DevicesTableConfig } from './models/devices-table-config.model';

@Component({
  selector: 'app-devices-details',
  templateUrl: './devices-details.component.html',
  styleUrls: ['./devices-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('table') table?: DatatableComponent;

  @ViewChild('moveDeviceModal') moveDeviceModalRef?: TemplateRef<Element>;

  @ViewChild('forceDeleteModalTemplate', { static: true })
  forceDeleteModalTemplate?: TemplateRef<Element>;

  @Input() selectedLocation?: Location;

  @Input() devicesTableConfig: DevicesTableConfig | undefined;

  @Input() devicesTableData: DeviceRow[] | undefined;

  @Input() edgeConnectivityInfo: EdgeConnectivityInfo | undefined;

  @Output() detailsClick = new EventEmitter<DeviceDetailsRow>();

  @Output() assignClick = new EventEmitter<DeviceDetailsRow>();

  @Output() addDevicesClick = new EventEmitter<Location>();

  private reloadDevices$: BehaviorSubject<string | undefined> =
    new BehaviorSubject(
      this.selectedLocation?.id ? this.selectedLocation?.id : undefined,
    );

  private devicesTableData$: Subject<DeviceRow[] | undefined> = new Subject();

  private reloadDevicesOnUpdateThingInstance: EventEmitter<void> =
    new EventEmitter();

  private readonly menuItemActionMapping: {
    [k: string]: (device: DeviceDetailsRow) => MenuItem;
  } = {
    details: (device: DeviceDetailsRow): MenuItem => ({
      title: 'GLOBALS.BUTTON.DETAILS',
      icon: 'element-settings',
      action: (): void => {
        this.detailsClick.emit(device);
      },
    }),
    replace: (): MenuItem => ({
      title: 'GLOBALS.BUTTON.REPLACE',
      icon: 'element-repeat',
      action: (): void => this.confirmReplaceEdgeDevice(),
    }),
    move: (device: DeviceDetailsRow): MenuItem => ({
      title: 'GLOBALS.BUTTON.MOVE_TO',
      icon: 'element-move',
      action: (): void => {
        this.moveDeviceAction(device);
      },
    }),
    edit: (device: DeviceDetailsRow): MenuItem => ({
      title: 'GLOBALS.BUTTON.EDIT',
      icon: 'element-edit',
      action: (): void => {
        this.getEditButtonAction(device);
      },
    }),
    update: (device: DeviceDetailsRow): MenuItem => ({
      title: 'GLOBALS.BUTTON.UPDATE',
      icon: 'element-convert',
      action: (): void => {
        this.siModalService.show(ThingInstanceUpdateModalComponent, {
          initialState: {
            device: device as DevicesTableRow,
            reloadTable: this.reloadDevicesOnUpdateThingInstance,
          },
          class: 'modal-dialog-centered',
          ignoreBackdropClick: false,
          keyboard: false,
        });
      },
    }),
    delete: (device: DeviceDetailsRow): MenuItem => ({
      title: 'GLOBALS.BUTTON.DELETE',
      icon: 'element-delete',
      action: () => {
        this.confirmDeleteDevice(device);
      },
    }),
  };

  rows$?: Observable<DeviceRow[] | undefined> = combineLatest([
    this.reloadDevices$,
    this.store.select((store) => ({
      floorsAndLocations: store.floorsAndLocations,
    })),
    this.devicesTableData$.pipe(startWith([])),
  ]).pipe(
    distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
    map(([locationId, { floorsAndLocations }, devicesTableData]) => {
      if (!locationId && floorsAndLocations.isLoading) {
        this.form = undefined;
        return undefined;
      }
      const devices = floorsAndLocations.locationsWithDevices[locationId!];

      if (!floorsAndLocations.locationsWithDevices[locationId!]) {
        return [];
      }
      this.createDevicesForm(
        floorsAndLocations.locationsWithDevices[locationId!],
      );

      return devices.map((device) => {
        const deviceTableRow = devicesTableData?.find(
          (row) => row.id === device.id,
        );

        return {
          ...device,
          ...deviceTableRow,
          ...this.getMenuItems(device),
        } as DeviceRow;
      });
    }),
  );

  form?: FormGroup;

  selectedDevice?: DeviceDetailsRow;

  isMobileResolution: boolean = false;

  cursorStyle: string = CURSOR_STYLE_DEFAULT;

  readonly mediaBaseUrl = environment.mediaBaseUrl;

  readonly statusMapping = statusMapping;

  readonly edgeDeviceType = 'SysXController';

  private subscriptions: Subscription[] = [];

  private subscriptionFormChanged: Subscription[] = [];

  errorCodeTranslateKeyMap = new Map<string, string>([
    ['required', 'FORM.INPUT_VALIDATION.REQUIRED'],
    ['minlength', 'FORM.INPUT_VALIDATION.MINLENGTH_NAME_ERROR'],
    ['maxlength', 'FORM.INPUT_VALIDATION.MAXLENGTH_NAME_ERROR'],
  ]);

  controlNameTranslateKeyMap = new Map<string, string>([
    ['name', 'FORM.CONTROL.NAME'],
  ]);

  private readonly deviceHideEditButton: {
    [k in WirelessDeviceType]: boolean;
  } = {
    BrdRout: true,
    Rout: true,
    RadVlvActr: true,
    WirelessRoomSensor: true,
  };

  isLoadingDevices$: Observable<boolean> = this.store.select(
    (store) => store.floorsAndLocations.isLoading,
  );

  constructor(
    private siActionDialogService: SiActionDialogService,
    private siModalService: SiModalService,
    private deviceActionService: DeviceActionService,
    private formAbstractionService: FormAbstractionService<Device>,
    private setupFormService: SetupFormService,
    private store: Store<{
      floorsAndLocations: LocationsState;
      project: ActiveProjectState;
    }>,
    private updateProjectStatusService: UpdateProjectStatusService,
    private edgeConnectivityModalService: EdgeConnectivityModalService,
    private siModal: SiActionDialogService,
    private router: Router,
    private thingVersionComparerService: ThingVersionComparerService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.deviceActionService.openMoveDeviceModal$.subscribe(() => {
        if (this.moveDeviceModalRef) {
          this.siModalService.show(this.moveDeviceModalRef, {
            class: 'modal-dialog-centered',
            ignoreBackdropClick: false,
            keyboard: false,
          });
        }
      }),
    );
    this.subscriptions.push(
      this.deviceActionService.openForceDeleteDeviceModal$.subscribe((open) => {
        if (open && this.forceDeleteModalTemplate) {
          this.invokeDeleteForceModal();
        }
      }),
    );
    this.subscriptions.push(
      combineLatest([
        this.store.select(selectProducts),
        this.store.select(selectBuildingProductLoadedStatus),
      ])
        .pipe(
          distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
          filter(([, loadedStatus]) => loadedStatus === LoadedStatus.LOADED),
          switchMap(([products]) =>
            this.updateProjectStatusService.updateProjectStatus(
              false,
              products,
            ),
          ),
        )
        .subscribe(),
    );
    this.subscriptions.push(
      this.reloadDevicesOnUpdateThingInstance.subscribe(() => {
        if (this.selectedLocation) {
          this.store.dispatch(
            LocationActions.initLoadLocationWithDevices({
              locationId: this.selectedLocation.id,
            }),
          );
          this.notifyReloadDevices(this.selectedLocation.id);
        }
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedLocation?.currentValue) {
      this.store.dispatch(
        LocationActions.getLocationWithDevices({
          locationId: changes.selectedLocation.currentValue.id,
        }),
      );
      this.notifyReloadDevices(changes.selectedLocation.currentValue.id);
    }

    if (this.devicesTableData) {
      this.devicesTableData$.next(this.devicesTableData);
      this.edgeConnectivityModalService.checkEdgeConnection(
        this.edgeConnectivityInfo,
      );
    }
  }

  recalculateTable(event: ElementDimensions) {
    this.isMobileResolution =
      event.width < MD_BREAKPOINT_SIZE_PIX_FOR_DEVICES_TABLE;
    this.table?.recalculate();

    if (this.isMobileResolution) {
      this.cursorStyle = CURSOR_STYLE_POINTER;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptionFormChanged.forEach((subscription) =>
      subscription.unsubscribe(),
    );
  }

  onInputKeyPress(e: KeyboardEvent, id: string): void {
    if (e.key.toLowerCase() === 'enter') {
      this.form?.controls[id].setValue((e.target as HTMLInputElement).value);
    }
  }

  openDetailsCardOnTap(row: DeviceDetailsRow): void {
    if (this.isMobileResolution && !this.isEdgeDevice(row)) {
      this.detailsClick.emit(row);
    }
  }

  onAddDevicesClick(): void {
    this.addDevicesClick.emit(this.selectedLocation);
  }

  isEdgeDevice(device: DeviceDetailsRow): boolean {
    return device.type === this.edgeDeviceType;
  }

  private createDevicesForm(devices: Device[]) {
    this.form = this.formAbstractionService.createForm(devices, [
      Validators.required,
      Validators.minLength(MinimumLengthForName),
      Validators.maxLength(MaximumLengthForName),
    ]);

    if (this.subscriptionFormChanged.length) {
      this.subscriptionFormChanged.forEach((subscription) =>
        subscription.unsubscribe(),
      );
      this.subscriptionFormChanged = [];
    }
    this.subscriptionFormChanged.push(
      this.formAbstractionService
        .onControlChange(this.form)
        .subscribe(({ id, value }) =>
          this.onDeviceNameChange({
            id,
            name: value,
            eTag: devices.find((device) => device.id === id)?.eTag ?? 0,
          }),
        ),
    );
  }

  private isEdgeDeviceCommissioned(device: DeviceDetailsRow): boolean {
    return (
      device.type === this.edgeDeviceType &&
      device.setupStatus === 'COMMISSIONED'
    );
  }

  private confirmDeleteDevice(device: DeviceDetailsRow): void {
    this.selectedDevice = device;
    this.siActionDialogService
      .showDeleteConfirmationDialog(
        'COMMISSIONING.DEVICE_TABLE.DELETE_DEVICE.DESCRIPTION',
        'COMMISSIONING.DEVICE_TABLE.DELETE_DEVICE.TITLE',
        'GLOBALS.BUTTON.DELETE',
        'GLOBALS.BUTTON.CANCEL',
      )
      .pipe(
        first(),
        tap((result) => {
          if (result === DeleteConfirmationDialogResult.Delete) {
            this.store.dispatch(
              LocationActions.initDeleteDevice({
                locationId: this.selectedLocation?.id
                  ? this.selectedLocation?.id
                  : '',
                device,
              }),
            );
          }
        }),
      )
      .subscribe();
  }

  initForceDelete() {
    if (this.selectedLocation && this.selectedDevice) {
      this.store.dispatch(
        LocationActions.initDeleteDevice({
          locationId: this.selectedLocation?.id,
          device: this.selectedDevice,
          force: true,
        }),
      );
    }
  }

  private invokeDeleteForceModal(): void {
    this.siModalService.show(this.forceDeleteModalTemplate!, {
      class: 'modal-dialog-centered',
      ignoreBackdropClick: false,
      keyboard: false,
    });
  }

  private moveDeviceAction(deviceRow: DeviceDetailsRow): void {
    this.selectedDevice = deviceRow;
    this.deviceActionService.openMoveDeviceModal$.next(true);
  }

  private getMenuItems(row: DeviceDetailsRow): {
    primaryActions: MenuItem[];
    secondaryActions: MenuItem[];
  } {
    return {
      primaryActions: [
        ...(!this.isEdgeDevice(row)
          ? [this.menuItemActionMapping.details(row)]
          : []),
      ],
      secondaryActions: [
        ...(this.devicesTableConfig?.showMoveActionButton
          ? [this.menuItemActionMapping.move(row)]
          : []),
        ...(!this.isEdgeDevice(row)
          ? this.getDeviceMenuItems(row)
          : this.getEdgeDeviceMenuItems(row)),
      ],
    };
  }

  private getDeviceMenuItems(row: DeviceDetailsRow): MenuItem[] {
    return [
      ...(!this.devicesTableConfig?.showDetailsButtonOnSmallerScreens
        ? [this.menuItemActionMapping.details(row)]
        : []),
      ...(this.showEditMenuItem(row)
        ? [this.menuItemActionMapping.edit(row)]
        : []),
      ...(this.showUpdateMenuItem(row)
        ? [this.menuItemActionMapping.update(row)]
        : []),
      this.menuItemActionMapping.delete(row),
    ];
  }

  private getEdgeDeviceMenuItems(row: DeviceDetailsRow): MenuItem[] {
    return [
      ...(!this.isEdgeDeviceCommissioned(row)
        ? [this.menuItemActionMapping.delete(row)]
        : []),
      ...(this.devicesTableConfig?.showReplaceEdgeActionButton
        ? [this.menuItemActionMapping.replace(row)]
        : []),
    ];
  }

  private showEditMenuItem(row: DeviceDetailsRow): boolean {
    return !!(
      row.config &&
      (!(this.getWirelessDeviceType(row.type) in this.deviceHideEditButton) ||
        !this.deviceHideEditButton[
          <WirelessDeviceType>this.getWirelessDeviceType(row.type)
        ])
    );
  }

  private showUpdateMenuItem(row: DeviceDetailsRow): boolean {
    return !!(
      this.devicesTableConfig?.showUpdateThingInstanceActionButton &&
      this.thingVersionComparerService.isVersionOutdated(
        row.instance?.model || ThingInstanceUpdateFallback,
        row.instance?.version || {
          model: ThingInstanceUpdateFallback,
          instance: ThingInstanceUpdateFallback,
        },
      )
    );
  }

  private confirmReplaceEdgeDevice(): void {
    this.siModal
      .showDeleteConfirmationDialog(
        'COMMISSIONING.DEVICE_TABLE.REPLACE_EDGE_DEVICE.DESCRIPTION',
        'COMMISSIONING.DEVICE_TABLE.REPLACE_EDGE_DEVICE.TITLE',
        'GLOBALS.BUTTON.RECOMMISSION',
        'GLOBALS.BUTTON.CANCEL',
      )
      .pipe(
        first(),
        filter((result) => result === DeleteConfirmationDialogResult.Delete),
        switchMap(() =>
          this.store.select((store) => store.project.activeProject?.id),
        ),
        tap((projectId) => {
          this.router.navigate([
            '/sidemenu',
            projectId,
            'commissioning',
            'connect',
            'edge-installation',
          ]);
        }),
      )
      .subscribe();
  }

  private getEditButtonAction(device: DeviceDetailsRow): void {
    this.setupFormService
      .display({ id: device.id, config: device.config, eTag: device.eTag })
      .pipe(
        first(),
        tap((setupFormModel) => {
          if (setupFormModel) {
            this.store.dispatch(
              LocationActions.patchFormDevice({
                deviceId: device.id,
                setupFormModel,
                eTag: device.eTag,
              }),
            );
          }
        }),
      )
      .subscribe();
  }

  private onDeviceNameChange(updateDevice: UpdateDevice): void {
    this.store.dispatch(
      LocationActions.initRenameDevice({
        device: updateDevice,
      }),
    );
  }

  private getWirelessDeviceType(type: ProductType): ProductType {
    return this.isWirelessRoomSensor(type) ? 'WirelessRoomSensor' : type;
  }

  private isWirelessRoomSensor(type: string): boolean {
    return WIRELESS_ROOM_SENSORS.includes(type as WirelessRoomSensorType);
  }

  private notifyReloadDevices(selectedLocation: string): void {
    this.reloadDevices$.next(selectedLocation);
  }
}
