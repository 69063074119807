import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { TranslateService } from '@ngx-translate/core';
import { JSONSchema7 } from 'json-schema';
import { set } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import {
  SetupFormSchema,
  SetupFormSchemaProperty,
} from '../api-client/services/lcas-api-client/models/setup-form.model';

@Injectable()
export class FormlySchemaConverterService {
  constructor(
    private translateService: TranslateService,
    private formlyJsonSchema: FormlyJsonschema,
  ) {}

  getFieldConfig(
    schemas: SetupFormSchema[],
    definitions?: Record<string, any>,
  ): Observable<FormlyFieldConfig[]> {
    return forkJoin(
      schemas.map((schema) =>
        forkJoin([
          this.getTranslatedSchema(schema),
          this.getTranslatedProperties(schema.properties),
        ]).pipe(
          map(([translatedSchema, properties]) => ({
            ...translatedSchema,
            properties,
          })),
        ),
      ),
    ).pipe(
      map((translatedSchemas) =>
        translatedSchemas.map((schema) =>
          this.formlyJsonSchema.toFieldConfig({
            ...schema,
            definitions,
          } as JSONSchema7),
        ),
      ),
    );
  }

  private getTranslatedSchema(
    schema: SetupFormSchema,
  ): Observable<SetupFormSchema> {
    return (
      schema.title ? this.translateService.get(schema.title) : of(undefined)
    ).pipe(
      map((translation) => ({
        ...schema,
        title: this.getTranslationOrFallback(
          translation,
          schema.title,
          schema.defaultTitle,
        ),
      })),
    );
  }

  private getTranslationOrFallback(
    translation: string,
    title?: string,
    defaultTitle?: string,
  ): string | undefined {
    return translation && translation !== title ? translation : defaultTitle;
  }

  private getTranslatedProperties(properties: {
    [key: string]: SetupFormSchemaProperty;
  }): Observable<{ [key: string]: SetupFormSchemaProperty }> {
    return forkJoin(
      Object.keys(properties).map((propertyName) =>
        this.getTranslatedProperty(properties[propertyName]).pipe(
          map((property) => ({
            property,
            propertyName,
          })),
        ),
      ),
    ).pipe(
      map((propertyArray) =>
        propertyArray.reduce(
          (previousValue, currentValue) => ({
            ...previousValue,
            [currentValue.propertyName]: currentValue.property,
          }),
          {},
        ),
      ),
    );
  }

  private getTranslatedProperty(
    schemaProperty: SetupFormSchemaProperty,
  ): Observable<SetupFormSchemaProperty> {
    return (
      schemaProperty.title
        ? this.translateService.get(schemaProperty.title)
        : of(undefined)
    ).pipe(
      map((translation) => ({
        ...schemaProperty,
        title: this.getTranslationOrFallback(
          translation,
          schemaProperty.title,
          schemaProperty.defaultTitle,
        ),
      })),
      mergeMap((translatedProperty) =>
        (translatedProperty.properties
          ? this.getTranslatedProperties(translatedProperty.properties!)
          : of({})
        ).pipe(
          map((translatedChildProperty) =>
            Object.keys(translatedChildProperty).length > 0
              ? set(translatedProperty, 'properties', translatedChildProperty)
              : translatedProperty,
          ),
        ),
      ),
    );
  }
}
